import managementIcon from "../assets/images/icon-fullM.svg";
import starIcon from "../assets/images/icon-star-little.svg";
import projectIcon from "../assets/images/icon-ListManagement.svg";
import crewIcon from "../assets/images/icon-crew.svg";
import cleaningIcon from "../assets/images/icon-cleaning.svg";
import repairIcon from "../assets/images/icon-repair.svg";
import shippingIcon from "../assets/images/icon-shipping.svg";
import deliveryIcon from "../assets/images/icon-YachtDelivery.svg";
import brokerageIcon from "../assets/images/icon-brokerage.svg";
import buyYachtsIcon from "../assets/images/icon-buy-yachts.svg";
import sellYachtsIcon from "../assets/images/icon-sell-yachts.svg";
import searchIcon from "../assets/images/icon-search-yachts.svg";

export const BLOG_MIAMI_URL = "https://blog.miamiyachting.net";

export const HOME_STEPS = [
  {
    name: "home",
    url: "top",
  },
  {
    name: "sales",
    url: "sales",
  },
  {
    name: "management",
    url: "management",
  },
  {
    name: "charters",
    url: "charters",
  },
  {
    name: "services",
    url: "services",
  },
];

export const PAGES_DATA = [
  {
    title: "Privacy Policy",
    id: "privacy-policy",
    url: "/privacy-policy",
  },
  {
    title: "Your one stop solution to all your yachting needs",
    id: "home",
    subtitle:
      "Our passion is yachting and our mission is to help you enjoy the yachting lifestyle through qualified assistance with the purchase & sale process and comprehensive management services solutions. Come onboard and enjoy the real yacht life.",
    url: "/about",
  },
  {
    title: "About Us",
    id: "about",
    url: "/about",
  },
  {
    title: "Yacht Sales",
    id: "sales",
    url: "/sales",
    items: [
      {
        icon: searchIcon,
        name: "Search for a Yacht",
        url: "https://sales.miamiyachting.net",
      },
      {
        icon: brokerageIcon,
        name: "Brokerage",
        url: "brokerage",
      },
      {
        icon: buyYachtsIcon,
        name: "Buying a Yacht",
        url: "buying-yachts",
      },
      {
        icon: sellYachtsIcon,
        name: "Selling a Yacht",
        url: "selling-yachts",
      },
    ],
  },
  {
    title: "Yacht Management",
    id: "management",
    url: "/management",
    items: [
      {
        icon: managementIcon,
        name: "Full management",
        url: "full-management",
      },
      {
        icon: starIcon,
        name: "Tailored management",
        url: "custom-management",
      },
      {
        icon: projectIcon,
        name: "Project management",
        url: "project-management",
      },
    ],
  },
  {
    title: "Charters",
    id: "charters",
    url: "/charters",
  },
  {
    title: "Services",
    id: "services",
    url: "/services",
    items: [
      {
        icon: crewIcon,
        name: "Professional Crew",
        url: "professional-crew",
      },
      {
        icon: cleaningIcon,
        name: "Cleaning and detailing",
        url: "clean-and-detailing",
      },
      {
        icon: repairIcon,
        name: "Installations and repairs",
        url: "installations-and-repair",
      },
      {
        icon: shippingIcon,
        name: "International Shipping & Logistics",
        shortName: "Intl. Shipping & Logistics",
        url: "international-shipping",
      },
      {
        icon: deliveryIcon,
        name: "Yacht Delivery",
        url: "yacht-delivery",
      },
    ],
  },
  {
    title: "Blog",
    id: "blog",
    url: BLOG_MIAMI_URL,
  },
  {
    title: "Contact Us",
    id: "contact",
    url: "/contact",
  },
];

export const BASE_WEBSITE_URL =
  process.env.REACT_APP_BASE_WEBSITE_URL ?? "http://miamiyachting.net";
export const FORMSPREE_ENDPOINT = "https://formspree.io/f/mdoyqavg";
export const IG_URL = "https://www.instagram.com/miami_yachting/?hl=en";
export const FB_URL =
  "https://www.facebook.com/pg/Miami-Yachting-1723862944578162";
export const YOUTUBE_URL = "https://www.youtube.com/@miamiyachting8457";
export const WHATSAPP_URL = "https://wa.me/17862019652";
export const YATCO_API_MIAMI_YACHTING_COMPANY_ID = 1459;
export const YATCO_API_SUBCATEGORY_CENTER_CONSOLE_ID = 3161;
export const YATCO_API_SUBCATEGORY_SEDAN_CRUISER_ID = 3153;

// Forms constants
export const DEFAULT_VALUE = "";
export const EMPTY_OPTION = { id: "", label: "" };
export const DEFAULT_OPTIONS = [EMPTY_OPTION];

export const CATEGORIES = [
  { category: "Motor Yacht", type: 2 },
  { category: "Cruising Yacht", type: 2 },
  { category: "Sport Fisherman", type: 2 },
  { category: "Boats", type: 2 },
];

export const CATEGORY_SAILING_YACHTS = 1;

export const NUMBER_OF_ITEMS_BY_CATEGORY = 4;
